@import '../../marketplace.css';

.root {
  display: flex;
  flex-flow: column wrap;
  position: sticky;
  top: 78px;
  z-index: calc( var(--zIndexTopbar) - 1 );
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  max-width: var(--ContainerWidthLarge);
  padding: 10px var(--ContainerHorizontalPadding);
  background-color: #fff;
  @media (--viewportMedium) {
    top: 0;
  }
  @media (--viewportLarge) {
    top: 0;
  }
}

.top {
  display: none;
  flex-flow: row nowrap;
  gap: 20px;
  width: 100%;
  @media (--viewportMedium) {
    display: flex;

  }
}

.mobileFilterButtons {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--spacing-s);
  @media (--viewportMedium) {
    display: none;
  }
}

.mobileFilterButton {
  @apply --marketplaceButtonStylesSecondary;
  border-color: var(--c-grey-200);
  text-transform: none;
  color:#000;
  letter-spacing: 0;
  font-weight: var(--fontWeightSemiBold);
  width: 50%;
}

.root[aria-expanded='true'] {
  z-index: calc( var(--zIndexTopbar) - 1 );
  position: fixed;
  height: 100%;
}

[aria-expanded='true'] .top {
  display: flex;
}

.DesktopSortBy { 
  @media (max-width: 550px) {
    display: none;
  }
}

.mobileSortBy { 
  width: 50%;
font-size: 16px;
line-height: 1;
position: relative;
}


.mobileSortBy label {
  @apply --marketplaceButtonStylesSecondary;
  width: 100%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  border-color: var(--c-grey-200);
  background-color: #FFF;
  line-height: 1;
  text-transform: none;
  color:#000;
  letter-spacing: 0;
  font-weight: var(--fontWeightSemiBold);
}

.mobileSortBy select{ width: 100%;}